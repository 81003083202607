import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  alignment: string
  cta?: ButtonProps[]
  description?: string
  firstImage?: ImageProps
  secondImage?: ImageProps
  subtitle?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  alignment,
  cta,
  description,
  firstImage,
  secondImage,
  subtitle,
  title,
}: Props) {
  return (
    <Container className={`adv-${alignment}`}>
      <Fade bottom distance="3.75rem">
        <Wrapper dial={alignment === 'left' ? 4 : 6}>
          <Images className="adv-images" row wrap space="between">
            <FirstImage>
              {firstImage ? <Image {...firstImage} /> : null}
            </FirstImage>
            <SecondImage>
              {secondImage ? <Image {...secondImage} /> : null}
            </SecondImage>
          </Images>
          <Text>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta
              ? cta.map((item, index) => (
                  <Button key={index} {...item} variant="simple" />
                ))
              : null}
          </Text>
        </Wrapper>
      </Fade>
    </Container>
  )
})

const Container = styled.div`
  max-width: 77.125rem;
  margin: 9.75rem auto;
  padding: 0 1.5rem;
  &.adv-left {
    .adv-images {
      right: 0;
      left: auto;
    }
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto;
  }

  @media (max-width: 767px) {
    margin: 6rem auto;
  }
`

const Wrapper = styled(FlexBox)`
  min-height: 62vh;
  padding: 3.75rem 0;
  position: relative;

  @media (max-width: 1023px) {
    min-height: auto;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Images = styled(FlexBox)`
  width: 70%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 767px) {
    width: 100%;
    height: 26vh;
    position: relative;
  }
`

const ImageStyle = css`
  width: calc(50% - 0.75rem);
  height: 100%;
  background: ${theme.colors.variants.neutralLight3};
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const FirstImage = styled.div`
  ${ImageStyle}
`

const SecondImage = styled.div`
  ${ImageStyle}
`

const Text = styled.div`
  width: 40%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 5.625rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    padding: 3rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 1.5rem 0;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.9375rem;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.375rem;
  margin-top: 0.1875rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
`
