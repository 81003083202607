import styled from '@emotion/styled'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services?: ServiceProps[]
  title?: string
}

export const IconsServices = memo(function IconsServices({
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      <Separator />
      {title ? (
        <Fade bottom>
          <Title>{title}</Title>
        </Fade>
      ) : null}
      <Fade bottom distance="3.75rem">
        <Checklist dial={2} row wrap>
          {uniq(services)?.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Checklist>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  max-width: 62.5rem;
  margin: 8.75rem auto 9.75rem;
  padding: 0 1.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    max-width: none;
    margin: 3.75rem auto 6rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
`

const Checklist = styled(FlexBox)``
